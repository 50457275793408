import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import "../../commanComponents/componentCSS/dashboard.css";
import "../../commanComponents/componentCSS/vehicledashboard.css";
import { Link } from "react-router-dom";
import config from "../../../config";
import config1 from "../../../config";
import Header from "../../commanComponents/usernavbar"
import Cookies from "js-cookie";
import Testimonial from "./testimonial"
import "../css/staffdashboard.css"
import { useNavigate } from "react-router-dom";
import Browseservice from "./browseservice";
import CanvasJSReact from "@canvasjs/react-charts";
import {
  getUserProfileAction,
  getUpcomingBookingAction,
  getReviewAction,
  getSalesPropertyAction,
  getDashboardStatisticsAction,
  getDashboardChartDataAction,
} from "../../../Action/user.action";

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Add the class to the body tag
    document.body.classList.add('staffbody');

    // Cleanup function to remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('staffbody');
    };
  }, []);

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const loginData = !Cookies.get("loginSuccessPropertyOwner")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));
  if (!loginData || loginData == "") {
    window.location.href = `${config.baseurl}`;
  }

  const [userProfile, setUserProfile] = useState([]);
  const [upcomingBooking, setupcomingBooking] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [salesReport, setSalesReport] = useState([]);
  const [dashboardStatistics, setDashboardStatistics] = useState([]);
  const [chartData, setChartData] = useState([]);
  const startYear = 2022;
  const currentYear = new Date().getFullYear();
  const [revenueYears, setRevenueYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    // Initialize revenue years
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }
    setRevenueYears(years);

    // Fetch initial data
    getProfileAPI();
    getUpcomingBookingAPI();
    getReviewAPI();
    getSalesPropertyAPI();
    getDashboardStatisticsAPI();
    getDashboardChartDataAPI(currentYear); // Load initial chart data for the current year
  }, []);
  const getDashboardChartDataAPI = async (year) => {
    let res = await getDashboardChartDataAction({ year });
    if (res.success) {
      const totalSum = res.data.reduce((sum, item) => parseFloat(sum) + parseFloat(item.total), 0);
      setTotalRevenue(totalSum);
  
      const defaultColor = "#E4A672";
      const hoverColor = "#E46422";
      let optionsArr = {
        animationEnabled: true,
        exportEnabled: true,
        toolTip: {
          shared: true,
          reversed: true,
        },
        legend: {
          verticalAlign: "center",
          horizontalAlign: "right",
          reversed: true,
          cursor: "pointer",
        },
        data: [
          {
            type: "stackedColumn",
            name: "Booking",
            yValueFormatString: "$#,##0.00", // This formats the tooltip with commas and 2 decimal places
            color: defaultColor,
            dataPoints: res.data.map((monthData, index) => ({
              label: [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
              ][index],
              y: monthData.total ? parseFloat(monthData.total) : 0, // Keep this as a number
            })),
            mouseover: function (e) {
              e.dataPoint.color = hoverColor;
              e.chart.render();
            },
            mouseout: function (e) {
              e.dataPoint.color = defaultColor;
              e.chart.render();
            },
          },
        ],
      };
      setChartData(optionsArr);
    }
  };
  

  const getProfileAPI = async () => {
    let res = await getUserProfileAction();
    if (res.success) {
      setUserProfile(res.data);
    }
  };

  const getUpcomingBookingAPI = async () => {
    let res = await getUpcomingBookingAction();
    if (res.success) {
      setupcomingBooking(res.data);
    }
  };

  const getReviewAPI = async () => {
    let res = await getReviewAction();
    if (res.success) {
      setReviews(res.data);
    }
  };

  const getSalesPropertyAPI = async () => {
    let res = await getSalesPropertyAction();
    if (res.success) {
      setSalesReport(res.data);
    }
  };

  const getDashboardStatisticsAPI = async () => {
    let res = await getDashboardStatisticsAction();
    if (res.success) {
      setDashboardStatistics(res.data);
    }
  };

  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value);
    getDashboardChartDataAPI(selectedYear);
    setSelectedYear(selectedYear);
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <section className="listing pt-5 pb-5 overflow-hidden">
        <Container>
          <Row className="">
            <Col lg={5} sm={12} xs={12} className="mb-4">
              <div className="d-flex profileMobile align-items-center">
                {!userProfile?.profile ||
                  userProfile?.profile == undefined ||
                  userProfile?.profile == null ? (
                  <img
                    src="assets/images/profile-img.png"
                    width="50px"
                    height="50px"
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    src={userProfile?.profile}
                    height="50px"
                    width="50px"
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                    className=""
                  />
                )}
                &nbsp;{" "}

                <div className="profileContent d-flex align-items-center">
                  <div className="">
                    {/* <p className="mb-0 text-lighter-grey d-md-none">Hi John Doe,</p> */}
                    <div>
                      <h5 className="mb-0 ms-2 me-3 fw-bold">
                        {" "}
                        Hi, {userProfile?.firstName
                          ? userProfile?.firstName
                          : "NA"}{" "}
                        {userProfile?.lastName ? userProfile?.lastName : ""}{" "}
                      </h5>
                    </div>
                  </div>

                  <img src="images/icon/hand.svg" width={`37px`} />
                </div>
              </div>
            </Col>
            <Col lg={7} sm={12} xs={12} className=" text-right">
              {/* <Link to={`${config.baseurl}servicedashboard`}>
                <Button
                  variant="outline-primary"
                  className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                >
                  Service Dashboard
                </Button>
              </Link> */}
              <a href={`${config.baseurl}vehicledashboard`}>
                <Button
                  variant="primary"
                  className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                >
                  Vehicle Dashboard
                </Button>
              </a>
            </Col>

            <Col xl={12} lg={12}>
              <div className="">
                <Row>
                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Link
                      className="dashboardLink"
                      to={`${config.baseurl}staffdashboard/listingproperty`}
                    >
                      <Card className="border-0">
                        <Card.Body className="">
                          <div className="d-flex align-items-center ">
                            <div className="me-xxl-4 me-xl-3 me-4  circleIcon">
                              <img
                                src="images/icon/vacant.svg"
                                width="36px"
                              />
                            </div>
                            <div>

                              <p className="mb-1 text-light-grey">Vacant Room</p>
                              <h4>
                                <b>
                                  {Math.max(
                                    0,
                                    parseInt(dashboardStatistics?.vacantProperty) > 0
                                      ? parseInt(dashboardStatistics?.vacantProperty) -
                                      parseInt(dashboardStatistics?.occupiedProperty)
                                      : 0
                                  )}
                                </b>
                              </h4>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>

                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Link
                      className="dashboardLink"
                      to={`${config.baseurl}staffdashboard/listingproperty`}
                    >
                      <Card className="border-0">
                        <Card.Body className="">
                          <div className="d-flex align-items-center ">
                            <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                              <img
                                src="images/icon/occupied.svg"
                                width="36px"
                              />
                            </div>
                            <div>
                              <p className="mb-1 text-light-grey">Occupied Room</p>
                              <h4>
                                <b>
                                  {parseInt(dashboardStatistics?.occupiedProperty) >
                                    0
                                    ? dashboardStatistics?.occupiedProperty
                                    : 0}
                                </b>
                              </h4>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>

                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Link
                      className="dashboardLink"
                      to={`${config.baseurl}staffdashboard/reservation`}
                    >
                      <Card className="border-0">
                        <Card.Body className="">
                          <div className="d-flex align-items-center ">
                            <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                              <img
                                src="images/icon/cancelled.svg"
                                width="36px"
                              />
                            </div>
                            <div>
                              <p className="mb-1 text-light-grey">Cancelled Room</p>
                              <h4>
                                <b>
                                  {parseInt(
                                    dashboardStatistics?.cancelledProperty
                                  ) > 0
                                    ? dashboardStatistics?.cancelledProperty
                                    : 0}
                                </b>
                              </h4>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                  
                  <Col xl={3} lg={6} md={6} sm={6} className="mb-3">
                    <Link
                      className="dashboardLink"
                      to={`${config.baseurl}staffdashboard/reservation`}
                    >
                      <Card className="border-0">
                        <Card.Body className="">
                          <div className="d-flex align-items-center ">
                            <div className="me-xxl-4 me-xl-3 me-4 circleIcon">
                              <img
                                src="images/icon/checkout.svg"
                                width="36px"
                              />
                            </div>
                            <div>
                              <p className="mb-1 text-light-grey">Checked Out Rooms</p>
                              <h4>
                                <b>
                                  {parseInt(
                                    dashboardStatistics?.checkedOutProperty
                                  ) > 0
                                    ? dashboardStatistics?.checkedOutProperty
                                    : 0}
                                </b>
                              </h4>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </div>
              <div className="graph">

                <Row className="mt-2" >

                  <Col xl={8} lg={12} className="mb-4">
                    <div
                      className="graph "
                      style={{
                        filter: loginData.loginType === 2 ? 'blur(5px)' : 'none',
                        pointerEvents: loginData.loginType === 2 ? 'none' : 'auto', // Optional: Prevent interactions when blurred
                      }}
                    >
                      <Card className="border-0">
                        <Card.Body className="">
                          <Row className="align-items-center">
                            <Col xl={6} lg={6} className="mb-md-3">
                              <h5 class="text-lighter-grey fw-normal">Total Revenue</h5>
                              <h2 className="fw-medium text-3xl mb-0">${totalRevenue ? parseFloat(totalRevenue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}
                              </h2>
                            </Col>
                            <Col xl={4} lg={3} className="mb-3">
                            </Col>
                            <Col xl={2} lg={3} className="mb-3">
                              <select
                                className="form-control"
                                onChange={handleYearChange}
                                value={selectedYear}
                              >
                                {revenueYears.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col lg={12}>
                              <hr className="mb-3 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                            </Col>
                          </Row>
                          <div className="">
                            <CanvasJSChart options={chartData} />
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>
                  <Col xl={4} lg={12} className="mb-4">
                    <div className="staff_upcoming_booking">
                      <Card className="border-0">
                        <Card.Body>
                          <div className="d-sm-flex align-items-center justify-content-between">

                            <div className=" mb-3"><h4 class="mb-0"><b>Upcoming Booking</b></h4></div>
                            {upcomingBooking.length > 5 ?
                              <div className=" mb-3 text-right"> <Link to={`${config1.baseurl + config1.loginurl}/reservation`}><Button variant="default" className="h-auto rounded-5 border-0 text-primary p-0">View all</Button></Link></div> : ''}
                          </div>
                          <div className="upcomingList">
                            <ul className="ps-0 list-style-none">
                              {upcomingBooking.length > 0 ? (
                                upcomingBooking.slice(0, 5).map((data) => (
                                  <li className="border-bottom pb-2">
                                    <Row className="align-items-center">
                                      <Col lg={3} xs={3}>
                                        {!data.propertyImage ||
                                          data.propertyImage == null ||
                                          data.propertyImage == undefined ? (
                                          <img
                                            src="assets/images/background/house.jpg"
                                            className="w-100"
                                          />
                                        ) : (
                                          <img
                                            src={data.propertyImage}
                                            className="w-100"
                                          />
                                        )}
                                      </Col>
                                      <Col lg={9} xs={9}>
                                        <div className="pt-2">
                                          <h6 className="mb-1 fw-bold">{data.propertyName}</h6>
                                          <h6 className="mb-1 text-primary small">By: {data.bookedBy}</h6>
                                          <div className="d-flex justify-content-between">
                                            <div className="text-grey small">{data.numberOfGuest} Guests, {data.numberOfAdults} Adults</div>
                                            <div className="fw-medium small fst-italic">
                                              {(() => {
                                                const [day, month, year] = data.bookedOn.split("-");
                                                const formattedDate = new Date(`${year}-${month}-${day}`).toLocaleDateString("en-US", {
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                                });
                                                return formattedDate;
                                              })()}
                                            </div>


                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </li>
                                ))
                              ) : (
                                <li>
                                  <Row>
                                    <Col lg={12} className="">
                                      <Card className="pt-3 border-0 no_data_found">
                                        <span className="text-center">
                                          <div>
                                            <img
                                              width="150"
                                              src="assets/images/listing_img.svg"
                                            />
                                            <h5 className="fs-6 mb-0 mt-3">
                                              No Data Found!
                                            </h5>
                                          </div>
                                        </span>
                                      </Card>
                                    </Col>
                                  </Row>
                                </li>
                              )}
                            </ul>
                          </div>

                        </Card.Body>
                      </Card>

                    </div>
                  </Col>

                </Row>


              </div>
              <div className="testimonial mb-4">
                <Col lg={12}>
                  <Card className="border-0 bg-white p-3">
                    <Card.Header>
                      <div className="d-flex justify-content-between ">
                        <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Latest Reviews </h4></div>
                        <div>
                          <Button onClick={() =>
                            navigate(
                              `${config1.baseurl + config1.loginurl}/Ratings`,
                              { state: "view" }
                            )
                          } variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div>
                      </div>

                    </Card.Header>
                    <Card.Body className="pt-0 px-0">
                      <div className="testimonialSlider">
                        <Testimonial />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
              <div className="services">
                <Col lg={12}>
                  <Card className="border-0 bg-white p-3">
                    <Card.Header>
                      <div className="d-flex justify-content-between ">
                        <div className="d-flex align-items-end"><h4 className="mb-0 fw-bolder">Browse Services </h4></div>
                        {/* <div><Button variant="default" className="border-0 p-0 h-auto text-primary">See all</Button></div> */}
                      </div>

                    </Card.Header>
                    <Card.Body className="pt-0 px-0">
                      <div className="">
                        <Browseservice />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </div >
  );
};

export default Dashboard;