import { useEffect, useState } from "react";
import config from "../../config";
import config1 from "../../coreFIles/config";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  Button,
  Offcanvas,
} from "react-bootstrap";
import Cookies from "js-cookie";
import { logoutAction } from "../../Action/user.action";
import {
  getPlanPurchaseDetailsAction,
  getUserProfileAction,
} from "../../Action/user.action";
import toast from "react-hot-toast";

const loginData = !Cookies.get("loginSuccessPropertyOwner")
  ? []
  : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const Header = () => {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState([]);

  const signUpPage = () => {
    navigate(`${config.baseurl}signup`);
  };

  const loginPage = () => {
    navigate(`${config.baseurl}login`);
  };

  // useEffect(() => {
  //   // if (loginData?.length !== 0) {
  //     getProfileAPI();
  //   // }
  // }, []);

  useEffect(() => {
    const fetchData = () => {
      getProfileAPI();
    };
    // Call the function initially
    fetchData();
    // Set up an interval to call the function every 10 seconds
    const interval = setInterval(fetchData, 10000); // 10000 milliseconds = 10 seconds
    // Clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  let urlParameter = window.location.pathname.split("/").pop();
  const getProfileAPI = async () => {
    if (loginData?.id) {
      let res = await getUserProfileAction();
      if (res.success) {
        setUserProfile(res.data);
      }
    }
  };

  const redirectPage = async (page) => {
    window.location.href = page;

  };

  const redirectUrl = async () => {
    if (loginData?.length !== 0) {
      window.location.href = `${config.baseurl}${config.loginurl}/propertyBooking`;
    }
    toast.success("Please Login to Register Your Property");
  };

  const logout = async () => {
    // await logoutAction();
    Cookies.remove("loginSuccessPropertyOwner");
    window.location.href = `${config.baseurl}userlogin`;
  };

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-white pt-2 pb-2 ">
          <Container>
            <Navbar.Brand href="#">
              <img src="assets/images/logo/logo.webp" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src="assets/images/logo/logo.webp" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-md-end flex-grow-1 align-items-xl-center">
                  <Nav.Link
                    className={urlParameter == "" ? "active" : ""}
                    href={`${config.baseurl}`}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    className={urlParameter == "aboutUs" ? "active" : ""}
                    href={`${config.baseurl}aboutUs`}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    className={urlParameter == "blog" ? "active" : ""}
                    href={`${config.baseurl}blog`}
                  >
                    Blogs
                  </Nav.Link>
                  <Nav.Link
                    className={urlParameter == "contactus" ? "active" : ""}
                    href={`${config.baseurl}contactus`}
                  >
                    Contact Us
                  </Nav.Link>

                  {loginData?.id ? (
                    <>
                      {/* <Nav.Link><Button onClick={() => redirectPage('dashboard/')} variant='primary' className='rounded-5 px-3'>Switch To Hosting</Button></Nav.Link> */}

                      <Nav className="">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="profileDropdown"
                          >
                            {!userProfile?.profile ||
                              userProfile?.profile == undefined ||
                              userProfile?.profile == null ? (
                              <img
                                src="assets/images/profile-img.png"
                                width="35px"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={userProfile?.profile}
                                width="35px"
                                height="35px"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                                className=""
                              />
                            )}
                            &nbsp;{" "}
                            {loginData?.firstName ? loginData?.firstName : "NA"}{" "}
                            {loginData?.lastName ? loginData?.lastName : ""}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              onClick={() => redirectPage("")}
                            >
                              Home
                            </Dropdown.Item>

                            <Dropdown.Item
                              href="#"
                              onClick={() => redirectPage("staffdashboard")}
                            >
                              Switch To Hosting
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${`${config.baseurl}${config.loginurluser}/propertyBooking`}`}
                            >
                              Booking
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${`${config.baseurl}${config.loginurluser}/mywishlist`}`}
                            >
                              Wishlist
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${`${config.baseurl}${config.loginurluser}/profile#notification`}`}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                Notification{" "}
                                {userProfile?.totalUnreadNotification > 0 ? (
                                  <span className="msg">
                                    {userProfile?.totalUnreadNotification}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${config.baseurl}${config.loginurluser}/profile`}
                            >
                              Profile
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${`${config.baseurl}${config.loginurluser}/profile#login_security`}`}
                            >
                              Login & Security
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${config.baseurl}${config.loginurluser}/ticketmanagement/1`}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                Get Help{" "}
                                {userProfile?.totalSupportUnreadMsg > 0 ? (
                                  <span className="msg">
                                    {userProfile?.totalSupportUnreadMsg}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item
                              href={`${config.baseurl}${config.loginurluser}/ticketmanagement/2`}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                Inbox
                                {userProfile?.totalSupportHostUnreadMsg > 0 ? (
                                  <span className="inboxmsg">
                                    {userProfile?.totalSupportHostUnreadMsg}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Dropdown.Item>

                            <Dropdown.Item onClick={logout} className="">
                              Log Out
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Nav>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outline-primary"
                        onClick={() => redirectPage("userlogin")}
                        className="rounded-5 px-4"
                      >
                        Login
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant="outline-primary"
                        onClick={() => redirectPage("usersignup")}
                        className="rounded-5 px-4"
                      >
                        Signup
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => redirectPage("login")}
                        // onClick={() => redirectUrl()}
                        variant="primary"
                        className="rounded-5 px-4"
                      >
                        Register Property
                      </Button>
                    </>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Header;
